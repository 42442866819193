<!--
 * @Description: 预约
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-30 10:04:10
 * @LastEditors: Seven
 * @LastEditTime: 2022-05-24 11:08:47
-->
<template>
  <div class="appointment">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
          <span class="tab-bar clearfix">
            <span
              @click="handleClick(item.path)"
              v-for="(item, index) in tabList"
              :class="{ active: item.path === activeName }"
              :key="index"
              >{{ item.name }}</span
            >
          </span>
          <div style="line-height: 40px">
            <!-- 转nc -->
            <el-button v-if="permUtil.WbCusotmer.transferselfBtn()" :disabled="!currList.length || currList.length>1" class="hq-button" type="primary" @click="transferbtn()"
                >转NC</el-button
              >
            <!-- 导出 -->
            <Export v-if="permUtil.WbCusotmer.invalidExport()" :pageParam="pageParam" :searchParam="searchParam" :total="total" code="appointment" />
          </div>
        </div>
      </div>
      <div style="width: 94%; margin: 0 auto">
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="appointment"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @sort-change="sortChange"
          @row-click="clickRow"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="120"
            
            sortable="custom"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="客户编号"
            prop="customerId"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约校区"
            prop="schoolName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约时间"
            prop="appointmentTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="到访状态"
            prop="visitStatusStr"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <div :style="{color: scope.row.visitStatus == 1?'green':scope.row.visitStatus == -1?'red':''}">
                {{ scope.row.visitStatusStr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约老师"
            prop="teacherName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="到访时间"
            prop="visitedTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="流失原因"
            prop="lostReason"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="所属赛道"
            prop="trackTypeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="截止日期"
            prop="expireTime"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属销售"
            prop="ownerUserName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约单创建人"
            prop="createName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="操作"
            min-width="240"
            
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button v-if="permUtil.WbCusotmer.appiontUpdate()" type="text" @click="edit(scope.row)">到访状态</el-button>
              <!-- <el-button v-if="!scope.row.isPoint && permUtil.WbCusotmer.appiontTransfer() && scope.row.canTransfer" type="text" @click="transfer(scope.row)"
                >转NC</el-button
              > -->
              <el-tooltip :disabled="scope.row.isEdit" placement="top" content="预约单已过期，请重新预约再做单!">
                <span>
                  <el-button class="hq-button" :disabled="!scope.row.isEdit" v-if="scope.row.isPoint" size="mini" type="primary" @click="goSignUpPage(scope.row)"
                    >报名</el-button
                  >
                </span>
              </el-tooltip>
              <el-button v-if="scope.row.isEdit" type="text" @click="editAppoint(scope.row)"
                >修改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <HqModal :visible="eidtShow" title="到访状态" width="400" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="visitStatus">
              <CheckBox
                label="到访状态"
                :list="checkList"
                v-bind:select.sync="form.visitStatus"
                ref="check"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="visitedTime">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="year"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="year"
                    label="到访时间"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="year" no-title locale="zh-cn">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">取消</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(year)"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-time-select
              style="width: 100%"
              size="small"
              v-model="hour"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '23:59'
              }"
              placeholder="选择时间">
            </el-time-select>
          </el-col>
          <el-col :span="24" v-if="form.visitStatus == 1">
            <el-form-item>
              <v-textarea
                v-model="form.lostReason"
                outlined
                :height="80"
                name="input-7-4"
                label="流失原因"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="editSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 预约转NC -->
    <HqModal
      :visible="transferShow"
      title="转nc ：转商机申请有效期为转商机成功后的24H"
      width="450"
      @on-cancel="cancel"
    >
      <v-form ref="form" lazy-validation>
        <p>注意：请确认好到NC的成交号码，否则导致成交失败</p>
        <el-row
          :gutter="10"
          style="width: 300px;text-align: left;margin-left: 60px;}"
        >
          <el-col style="margin-top: 10px" :span="24"> 学员姓名：{{ transferForm.name }} </el-col>
          <el-col style="margin-top: 10px" :span="24"> 预约校区：{{ transferForm.schoolName }} </el-col>
          <el-col style="margin-top: 10px" :span="24"> 预约老师：{{ transferForm.teacherName }} </el-col>
          <el-col style="margin-top: 10px" :span="24"> 预约时间：{{ transferForm.appointmentTime }} </el-col>
          <el-col :span="24" style="margin-top: 10px">
            <v-select
              v-model="transferForm.phone"
              :items="phoneList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="成交号码"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
            ></v-select>
          </el-col>
        </el-row>
      </v-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="transferSave" type="primary" :loading="loadingBtntransfer">确认</el-button>
      </div>
    </HqModal>
    <!-- 预约单修改 -->
    <HqModal
      :visible="appointShow"
      title="修改"
      width="450"
      @on-cancel="cancel"
    >
      <el-form ref="appointForm" :model="appointForm" :rules="appointRules">
        <el-row>
          <el-col :span="24" style="padding-top: 12px">
            <el-form-item>
              <v-autocomplete
                v-model="appointForm.type"
                :items="[{name: '财经校区', id: 1}, {name: '虚拟校区', id: 2}, {name: '自考招生点', id: 3}, {name: '学历外部团队', id: 4}]"
                :menu-props="{ bottom: true, offsetY: true }"
                label="校区类型"
                item-text="name"
                item-value="id"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                @change="typeChange"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
          <div v-if="appointForm.type === 1">
            <el-col :span="24" style="margin-top: 10px">
              <el-form-item prop="provinceCode">
                <v-autocomplete
                  v-model="appointForm.provinceCode"
                  :items="provinceList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="省份"
                  item-text="name"
                  item-value="code"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="changeProvince"
                ></v-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="cityCode">
                  <v-autocomplete
                    v-model="appointForm.cityCode"
                    :items="cityList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="城市"
                    item-text="name"
                    item-value="code"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="changeCity"
                    no-data-text="暂无数据"
                  ></v-autocomplete>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="24">
            <el-form-item prop="schoolNcId">
              <v-autocomplete
                v-model="appointForm.schoolNcId"
                :items="schoolList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="预约校区"
                item-text="deptName"
                item-value="ncSchoolId"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                @change="changeSchool"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="teacherUserId">
              <v-autocomplete
                v-model="appointForm.teacherUserId"
                :items="teacherList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="招生老师"
                item-text="username"
                item-value="userId"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                @change="changeTeacher"
                no-data-text="暂无数据"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="appointSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 选择成交号码 -->
    <HqModal
      :visible="phoneShow"
      title="提示"
      width="450"
      height="500"
      @on-cancel="cancel"
    >
      <el-row>
        <el-col v-if="false" :span="24" style="margin-top: 10px">
          <v-select
            v-model="deptId"
            :items="deptList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="成交校区"
            outlined
            item-text="deptName"
            item-value="deptId"
            :dense="true"
            :height="32"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
          ></v-select>
        </el-col>
        <el-col style="margin-top: 10px" :span="24"><i class="el-icon-warning" style="color: red"></i>该商机存在多个号码，请选择成交录单号码</el-col>
        <el-col :span="24" style="margin-top: 16px">
          <v-select
            v-model="phone"
            :items="dealPhoneList"
            :menu-props="{ bottom: true, offsetY: true }"
            label="成交号码"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
          ></v-select>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="phoneSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getAppointmentList" />
  </div>
</template>
<script>
import {
  getUserCustomQuery,
  getCustomerPhone,
  getUserList,
  getAppointmentTeacher,
  getCitiesByProvinceCode,
  getAllProvinceList,
  getDeptparams,
  getVirtualschool,
  getRecruitSpinner,
  getRecruitSpinnernew,
} from "api/public";
import {
  getAppointmentList,
  doAppointmentUpdate,
  doAppointmentTransferNc,
  doAppointmentTeacherUpdate,
  getTransactionInfo,
  dozikaoTransferNc
} from "api/customer";
import { getQuerySchoolList } from "api/count";
import Search from "components/search";
import CheckBox from "components/check-box";
import Detail from "./components/detail";
import Export from "./components/export";

export default {
  components: {
    Search,
    CheckBox,
    Detail,
    Export,
  },
  data() {
    return {
      loadingBtntransfer: false,
      activeName: "",
      tabList: [
        { name: "全部", path: "" },
        { name: "未到访", path: "0" },
        { name: "预约到访", path: "1" },
        { name: "预约到访流失", path: "-1" },
      ],
      tableData: [],
      listLoading: false,
      pageSize: 10,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      searchShow: false,
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: "" },
        { name: "phone", label: "手机", type: "input", value: "" },
        {
          name: "createTime",
          label: "创建时间",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "teacherName",
          label: "预约老师",
          type: "input",
          value: "",
        },
        {
          name: "schoolName",
          label: "预约校区",
          type: "input",
          value: "",
        },
        {
          name: "appointmentTime",
          label: "预约时间",
          type: "date",
          value: [],
          ref: "appoint",
          show: false,
        },
        {
          name: "ownerUserId",
          label: "归属销售",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id",
        },
        {
          name: "createId",
          label: "预约单创建人",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id",
        },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
        {
          name: "trackType",
          label: "所属赛道",
          type: "dict",
          code: "BIZ_TYPE",
          value: "",
        },
        {
          name: "recruitChannels",
          label: "渠道名称",
          type: "allSelect",
          option: [],
          value: [],
          text: "name",
          id: "id"
        },
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "createTime"],
      searchParamFromApi: [],
      eidtShow: false,
      form: {
        lostReason: "",
        phone: "",
        visitedTime: [],
        visitStatus: null,
      },
      checkList: [
        { label: "未到访", value: 0 },
        { label: "预约到访流失", value: 1 }, // -1
        { label: "预约到访", value: 2 }, // 1
      ],
      phoneList: [],
      menu: false,
      currCol: {},
      transferShow: false,
      userList: [],
      transferForm: {},
      formRules: {
        visitStatus: [
          { message: "请选择到访状态", required: true }
        ]
      },
      searchParam: {},
      hour: "",
      year: "",
      // 预约单修改
      appointForm: {
        provinceCode: "",
        teacherUserId: ""
      },
      appointShow: false,
      // 省份
      provinceList: [],
      // 预约校区
      schoolList: [],
      // 招生老师
      teacherList: [],
      defaultSelect: {},
      appointRules: {
        provinceCode: [
          {message: "请选择省份", required: true}
        ],
        schoolNcId: [
          {message: "请选择预约校区", required: true}
        ],
        teacherUserId: [
          {message: "请选择招生老师", required: true}
        ]
      },
      // 页面是否有滚动条
      isScroll: true,
      // 城市
      cityList: [],
      phoneShow: false,
      phone: "",
      dealPhoneList: [],
      row: {},
      topHeight: 0,
      deptList: [],
      deptId: '',
      transferBtn: 0,
      currList: []
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.activeName = this.$route.query.type || ""
    this.activeName && this.$router.push({ query: { type: this.activeName } });
    this.getSearchList();
    this.getAppointmentList();
    getUserList().then((data) => {
      this.searchAllParam.map((item) => {
        if (
          item.name === "ownerUserId" ||
          item.name === "createId"
        ) {
          item.option = data.result;
        } else if (item.name === "recruitChannels") {
        getRecruitSpinner().then(data => {
          item.option = data.result
        })
      }
      });
    });
    getRecruitSpinnernew().then(data => {
          this.recruitChannelsList = data.result
        })
    getAllProvinceList().then(data => this.provinceList = data.result);
    // getQuerySchoolList().then((data) => this.deptList = data.result)
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      if(this.activeName) {
        this.$router.push({ query: { type: this.activeName } });
      } else {
        this.$router.push({query: {}})
      }
      this.getAppointmentList()
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("appointment");
      if (code === 0) {
        this.defaultParamList = ["name", "phone", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
      }
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getAppointmentList() {
      this.listLoading = true;
      if(this.activeName !== "") {
        this.pageParam.visitStatus = this.activeName
      } else {
        this.pageParam.visitStatus = ""
      }
      const { code, result, msg } = await getAppointmentList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date" || item.value === "0") {
            if (item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if (item.name === "appointmentTime") {
              this.searchParam.beginAppointmentTime = item.value[0];
              this.searchParam.endAppointmentTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getAppointmentList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "createTime" ||
          item.name === "appointmentTime") {
            item.value = []
          } else {
            item.value = "";
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.currList = []
      this.search();
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getAppointmentList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getAppointmentList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].asc = !(val.order == "descending")
      this.pageParam.sortItems[0].column = val.prop;
      this.getAppointmentList();
    },
    // 打开编辑框
    async edit(row) {
      this.year =""
      this.hour = ""
      this.form.lostReason = ""
      this.form = row
      if(row.visitedTime) {
        this.year = row.visitedTime.split(" ")[0]
        this.hour = row.visitedTime.split(" ")[1]
      }
      // { label: "未到访", value: 0 },
      //   { label: "预约到访流失", value: 1 }, // -1
      //   { label: "预约到访", value: 2 }, // 1
      let visitStatus
      if(row.visitStatus == 0) {
        visitStatus = 0
      } else if(row.visitStatus == -1) {
        visitStatus = 1
      } else if(row.visitStatus == 1) {
        visitStatus = 2
      }
      this.form.visitStatus = visitStatus
      this.$refs.check.set(visitStatus)
      this.$forceUpdate()
      this.eidtShow = true;
    },

    // 保存编辑
    async editSave() {
      if(this.year !== "") {
        if(this.hour !== "") {
          this.form.visitedTime = this.year + ' ' + this.hour + ':00' 
        } else {
          this.form.visitedTime = this.year + " " + "00:00:00"
        }
      }
      if(this.form.visitStatus == 0) {
        this.form.visitStatus = 0
      } else if(this.form.visitStatus == 1) {
        this.form.visitStatus = -1
      } else if(this.form.visitStatus == 2) {
        this.form.visitStatus = 1
      }
      const { code, msg } = await doAppointmentUpdate(this.form);
      if (code === 0) {
        this.$message.success(msg);
        this.getAppointmentList()
        this.eidtShow = false;
      }
    },

    // 取消编辑
    cancel() {
      (this.form = {
        lostReason: "",
        phone: "",
        visitedTime: null,
        visitStatus: "",
      }),
        // this.$refs.check.init();
      this.eidtShow = false;
      this.transferShow = false;
      this.appointShow = false
      this.phoneShow = false
    },
    // 单选事件
    clickRow(row) {
      this.currCol = row;
      this.currList = []
      this.currList.push(row)
      console.log('currList',this.currList.length);
    },
    // 转nc弹窗按钮
    async transferbtn() {
      this.transferForm = this.currCol
      this.transferBtn = 1
      const { code, result } = await getCustomerPhone(this.currCol.customerId);
      if (code === 0) {
        this.phoneList = result;
        this.transferForm.phone = result[0]
      }
      this.transferShow = true;
    },
    // 转nc弹窗
    async transfer(row) {
      this.currCol = row;
      this.transferForm = row
      this.transferBtn = 0
      const { code, result } = await getCustomerPhone(row.customerId);
      if (code === 0) {
        this.phoneList = result;
        this.transferForm.phone = result[0]
      }
      this.transferShow = true;
    },
    // 转NC保存
    async transferSave() {
      // if (this.transferBtn == 0) {
        // const { code, msg } = await doAppointmentTransferNc(this.transferForm);
        // if (code === 0) {
        //   this.$message.success(msg);
        //   this.currList = []
        // } else {
        //   this.$message.error(msg);
        // }
      // } else {
      //   }
        const { code, msg } = await dozikaoTransferNc(this.transferForm);
        if (code === 0) {
          this.$message.success(msg);
          this.currList = []
        } else {
          this.$message.error(msg);
        }
      this.transferShow = false;
    },
    // 报名
    async goSignUpPage(row){
      this.row = row
      let params = {
        customerId: row.customerId,
        sourceId: row.id,
        registSource: 0,
      }
      
      const data = await getTransactionInfo(params)
      if(data.code === 0) {
        getCustomerPhone(row.customerId).then(res => {
          let { code, result } = res;
          if (code === 0) {
            if(result.length == 1) {
              this.$router.push(`/afterSale/signup?customerId=${row.customerId}&registSource=0&isAppomit=1&sourceId=${row.id}`);   
            } else {
              this.dealPhoneList = result;
              this.phoneShow = true
            }
          }
        })
      } else {
        this.$message.error(data.msg)
      }
      
      // if(this.$store.state.user.loginUser.deptList[0].type == 1) {
      //   this.deptId = this.$store.state.user.loginUser.deptList[0].deptId
      // }
      
    },
    async phoneSave() {
      // if(!this.deptId) {
      //   this.$message.error("请选择成交校区！")
      //   return false
      // }
      if(!this.phone) {
        this.$message.error("请选择成交号码！")
        return false
      }
      this.$router.push(`/afterSale/signup?customerId=${this.row.customerId}&registSource=0&sourceId=${this.row.id}&phone=${this.phone}`)
      this.phoneShow = false
    },
    // 修改预约单
    editAppoint(row) {
      this.appointForm = JSON.parse(JSON.stringify(row))
      // getProvinceList({ schoolId: row.provinceCode }).then(data => this.schoolList = data.result)
      if(row.type === 1) {
        this.changeProvince(row.provinceCode, 1) 
      } else {
        this.typeChange(row.type, 1)
        if(row.schoolNcId) {
          this.changeSchool(row.schoolNcId, 1)
        }
      }
      this.appointShow = true
    },
    // 校区类型联调校区
    async typeChange(value, type) {
      if(type != 1) {
        this.appointForm.provinceCodecity = null
        this.appointForm.provinceCode = ""
        this.appointForm.teacherUserId = "";
        this.appointForm.teacherName = "";
        this.appointForm.schoolNcId = "";
        this.cityList = []  
      }
      if(value) {
        if(value !== 1) {
          const {code, result } = await getVirtualschool(value)
          if(code === 0) {
            this.schoolList = result
          }
        } else {
          this.schoolList = []
        } 
      }
    },
    // 省份联动校区
    changeProvince(schoolId, type) {
      if(type !== 1) {
        this.appointForm.schoolNcId = ""
        this.appointForm.teacherUserId = ""
        this.appointForm.cityCode = ""
      }
      if (schoolId) {
        this.provinceList.map(item => {
          if(schoolId === item.code) {
            this.appointForm.provinceName = item.name
          }
        })
        // 获取城市数据
        getCitiesByProvinceCode(schoolId).then(data => {
          this.cityList = data.result
          type === 1 && this.cityList.map(item => {
            if(item.code === this.appointForm.cityCode) {
              this.appointForm.provinceCodecity = item.id
              this.appointForm.cityName = item.name
            }
          })

          // 获取校区
          this.changeCity(this.appointForm.provinceCodecity, 1)
        })
      }
    },
    // 城市联动校区
    changeCity(value, type) {
      if(value) {
        if(type !== 1) {
          this.appointForm.schoolNcId = ""
          this.appointForm.teacherUserId = ""
          this.cityList.map(item => {
            if(item.code === this.appointForm.cityCode) {
              this.appointForm.provinceCodecity = item.id
              this.appointForm.cityName = item.name
            }
          })
          value = this.appointForm.provinceCodecity
        }
        getDeptparams({ id: value }).then(data => {
        this.schoolList = data.result;

        this.changeSchool(this.appointForm.schoolNcId, 1)
      });
      }
    },
    // 校区联动招生老师
    changeSchool(schoolId, type) {
      if(type !== 1) {
        this.appointForm.teacherUserId = ""
      }
      if (schoolId) {
        this.schoolList.map(v => {
          if(v.ncSchoolId == schoolId) {
            this.appointForm.ncuserPk = v.teacherPk
            this.appointForm.schoolDeptId = v.deptId
          }
        })
        getAppointmentTeacher({ deptId: this.appointForm.schoolDeptId }).then(data => {
          this.teacherList = data.result
          this.appointForm.teacherUserId && this.changeTeacher(this.appointForm.teacherUserId)
        });
      }
      this.schoolList.map((item) => {
        if (schoolId == item.ncSchoolId) {
          this.appointForm.schoolName = item.deptName;
        }
      });
    },
    changeTeacher(value) {
      this.teacherList.map(item => {
        if(item.userId === value) {
          this.appointForm.teacherName = item.username
          this.appointForm.teacherPk = item.ncUserPk
        }
      })
    },
    // 保存预约单修改
    appointSave() {
      this.$refs.appointForm.validate(async (valid) => {
        if(valid) {
          const res = await doAppointmentTeacherUpdate(this.appointForm)
          if(res.code === 0) {
            this.getAppointmentList()
            this.$message.success(res.msg)
            this.cancel()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.appointment {
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .tab-bar {
      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 30px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
}
</style>
